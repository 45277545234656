import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import residentialImg from '../../../assets/images/residential-img.jpg'

class residentialGatePage extends React.Component {
  render() {
    const siteTitle =
      'Residential Gate Services - Dallas-Fort Worth Home Gate Installation Services'
    const siteDescription =
      'Do you need a gate installed on your residential property? We provide gate installation services for Arlington, Dallas, and Fort Worth. Contact us today!'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Residential Gate Services</h1>
            <hr />
            <p className="text-white mx-auto">
              Whether you’re building a new home or remodeling the home you are
              already in, J & J Gates Service and Design has a full line of the
              industry’s highest quality products—automatic gates, garage doors
              from LiftMaster gate operators, garage door openers (door
              operators) and access systems—that will add style to your home. We
              will also install your gate and garage door for you. We are your
              full service, residential, commercial and industrial gate
              installation and repair company.{' '}
              <strong className="primary-color">
                Call <a href="+1-817-466-2794">(817) 466-2794</a> today for
                residential gates in Dallas and Fort Worth, TX.
              </strong>
            </p>
          </div>
        </div>

        <div className="container d-padding-t d-padding-b">
          <div className="row d-padding-b text-justify">
            <div className="col-md-8">
              <h4 className="mt-0">Residential Gate Services</h4>
              <hr className="ml-0" />
              <p>
                J & J Gates Service and Design offer competitive pricing due to
                our ability to manufacture and assemble automatic gate
                components in-house. You can always count on fair prices and
                quality workmanship when you come to us for residential gates!
                Above all, it’s the people behind the product that matters most.
                From your initial meeting with us to the final installation,
                your experience with the J & J Gates Service and Design team is
                what counts. We pride ourselves on detailed communication,
                quality installation, and friendly service. We are focused on
                our automatic gate installation and repair business and it
                shows. You can be confident when you buy from the experienced,
                friendly, reliable team at J & J Gates Service and Design.
              </p>
            </div>
            <div className="col-md-4">
              <LazyLoadImage
                className="w-100"
                src={residentialImg}
                title="Gate Installation"
                alt="Gate"
              />
            </div>
          </div>

          <div className="set-width text-center mx-auto">
            <h4 className="mt-0">Gate Services We Offer</h4>
            <hr />
            <p>
              We offer a wide array of services to meet the gate needs of the
              DFW community. This includes custom design, installation, and
              repair of residential gates, openers, and access control systems.
              Not just any gate company will provide repairs to gates that were
              not installed by their technicians, but we do. We know and
              understand the importance of being versatile and putting the needs
              of customers first. Therefore, no matter what the contractor
              installed your gate automation, we can and will provide repairs,
              or will install a new one if necessary.
            </p>
            <p>
              <strong>
                If you’re looking for professional residential gate service in
                Dallas and Fort Worth, TX, call J & J Gates Service and Design
                at <a href="+1-817-466-2794">(817) 466-2794</a>
              </strong>
              . We are happy to answer any questions you have about our services
              or products available!
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default residentialGatePage

export const residentialGatePageQuery = graphql`
  query residentialGatePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
